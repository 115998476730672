import { Injectable } from "@angular/core";
import { Client } from "app/shared/model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private isActiveClientSubject = new BehaviorSubject<Client>(null);
  private isDiscountActive = new BehaviorSubject<boolean>(false);
  private loggedClient = new BehaviorSubject<Client>(undefined);

  setIsActiveClient(isActive: Client): void {
    this.isActiveClientSubject.next(isActive);
  }

  getIsActiveClient(): Observable<Client> {
    return this.isActiveClientSubject.asObservable();
  }

  getValueActiveClient(): Client | null {
    return this.isActiveClientSubject.value;
  }

  setIsDiscountActive(isActive: boolean): void {
    this.isDiscountActive.next(isActive);
  }

  getIsDiscountActive(): Observable<boolean> {
    return this.isDiscountActive.asObservable();
  }

  setloggedClient(value: Client): void {
    this.loggedClient.next(value);
  }

  getloggedClient(): Observable<Client> {
    return this.loggedClient.asObservable();
  }
}
